import { Card } from "react-bootstrap";
import {Link} from 'react-router-dom';
import Rating from './Rating'
import './Product.css';

const Product = ({ product }) => {
    return (
        <Card className="my-3 p-3 rounded">
            <Link to={`/product/${product._id}`}>
            <Card.Img src={product.image} variant="top" />
            </Link>

            
            <Card.Body>
                <Link style={{ textDecoration: 'none' }} to={`/product/${product._id}`}>
                    <Card.Title as="div" className="product-title">
                        <strong>{product.name}</strong>
                    </Card.Title>
                </Link>

                <Card.Text as="div" className='py-1'>
                    <Rating value={ product.rating } text={`${" "} ${product.numReviews} reviews`}/> 
                </Card.Text>

                <Card.Text as="h3" style={{ marginTop: '9px' }}>
                    ${product.price}
                </Card.Text>
            </Card.Body>
        </Card>

        
        
    )
};

export default Product;